<template>
  <div class="bar">
    <van-icon @click="fh"
              name="arrow-left"
              color="#333333"
              size="17" />
    <div @click="go">
      <img src="../assets/images/order.png"
           alt
           class="orderImg" />
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
Vue.use(Icon)
export default {
  name: 'TopBar',
  props: {},
  methods: {
    go () {
      console.log(11);
      this.$router.push({
        path: '/order'
      })
    },
    fh () {
      this.$router.push({
        path: '/index'
      })
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bar {
  position: fixed;
  top: 0;
  z-index: 99;
  width: calc(100vw - 20px);
  height: 40px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  .orderImg {
    width: 17px;
    height: 20px;
  }
}
</style>
