<!-- 这是打车H5的一个DEMO架子 已配置了移动端单位适配 会自动把px转为rem 写样式的时候用px就好
    地图组件使用的是高德的JSAPI
    只写了一点点😅
 -->
<template>
  <div class="home">
    <TopBar class="topbar" />
    <div id="container"></div>
    <div class="infoBox">
      <van-tabs v-model="active"
                class="tabs"
                @click="onclick">
        <van-tab title="现在">
          <div style="margin-top:20px"
               class="locationInfo">
            <div class="statrt"
                 @click="end(1)">
              <img style="width:10px;height:10px"
                   src="../../assets/images/startAddress.png"
                   alt />
              <input type="text"
                     v-model="topcar"
                     placeholder="请输入上车地址"
                     class="input" />
            </div>
            <van-divider />
            <div class="end"
                 @click="end(2)">
              <img style="width:10px;height:10px"
                   src="../../assets/images/endAddress.png"
                   alt />
              <input v-model="endAddress"
                     type="text"
                     placeholder="请输入下车地址"
                     class="input" />
            </div>
          </div>
        </van-tab>
        <van-tab title="预约">
          <div style="margin-top:20px"
               class="locationInfo2">
            <div class="time">
              <div style="display:flex">
                <img style="width:10px;height:10px;margin-top:20px"
                     src="../../assets/images/appointment.png"
                     alt />
                <van-field readonly
                           clickable
                           name="datetimePicker"
                           :value="value1"
                           placeholder="请选择上车时间"
                           @click="showPicker = true" />
                <van-popup v-model="showPicker"
                           position="bottom">
                  <van-datetime-picker v-model="currentDate"
                                       type="datetime"
                                       title="请选择上车时间"
                                       :min-date="minDate"
                                       :max-date="maxDate"
                                       @confirm="onchange"
                                       @cancel="showPicker=false" />
                </van-popup>
              </div>
            </div>
            <van-divider />
            <div class="statrt"
                 @click="end(1)">
              <img style="width:10px;height:10px"
                   src="../../assets/images/startAddress.png"
                   alt />
              <input type="text"
                     v-model="topcar"
                     placeholder="请输入上车地址"
                     class="input"
                     disabled />
            </div>
            <van-divider />
            <div class="end"
                 @click="end(2)">
              <img style="width:10px;height:10px"
                   src="../../assets/images/endAddress.png"
                   alt />
              <input v-model="endAddress"
                     class="input"
                     type="text"
                     placeholder="请输入下车地址" />
            </div>
            <van-divider />
          </div>
        </van-tab>
        <!-- <van-tab title="接机">
          <div style="margin-top:20px"
               class="locationInfo3">
            <div class="time">
              <div style="display:flex">
                <img style="width:10px;height:10px;margin-top:20px;"
                     src="../../assets/images/appointment.png"
                     alt />
                <van-field readonly
                           clickable
                           name="datetimePicker"
                           :value="value2"
                           placeholder="选择航班到达时间"
                           @click="showPicker = true" />
                <van-popup v-model="showPicker"
                           position="bottom">
                  <van-datetime-picker v-model="flightDate"
                                       type="date"
                                       title="选择航班到达时间"
                                       :min-date="minDate"
                                       :max-date="maxDate"
                                       @confirm="onchange2"
                                       @cancel="showPicker=false" />
                </van-popup>
              </div>
            </div>
            <van-divider />
            <div class="statrt"
                 style="height:40px">
              <img style="width:10px;height:10px"
                   src="../../assets/images/startAddress.png"
                   alt />
              <input @input="fly"
                     type="text"
                     v-model="flightNum"
                     placeholder="请输入航班号"
                     style="margin-left:10px;border:0" />
              <div style="margin-left:20px;color:#333">{{this.add}}</div>
            </div>
            <van-divider />
            <div class="end"
                 @click="end(3)">
              <img style="width:10px;height:10px"
                   src="../../assets/images/endAddress.png"
                   alt />
              <input v-model="endAddress"
                     class="input"
                     style="margin-left:10px;border:0;width:80%"
                     type="text"
                     placeholder="请输入终点" />
            </div>
          </div>
        </van-tab> -->
      </van-tabs>
    </div>
  </div>
</template>
<script>
import TopBar from '@/components/TopBar.vue'
import moment from 'moment'
import { Toast } from 'vant'

import Vue from 'vue'
import { Loading } from 'vant';

Vue.use(Loading);
// import { Tab, Tabs } from 'vant'

// Vue.use(Tab)
// Vue.use(Tabs)
import { aviation_api, bascitys_api } from '../../http/api/price'

export default {
  name: 'Home',
  components: {
    TopBar,
  },
  data () {
    return {
      add: '',
      flight: '', //航班号
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      value2: '',
      value1: '',
      showPicker: false,
      endAddress: '',
      endIp: null,
      topcar: null,
      value: '',
      map: null,
      polylineL: null,
      infoWindow: null,
      mapContent: '',
      zoom: '',
      markers: [],
      cluster: null,
      active: 1,
      type: null,
      location: [],
      flightNum: '',
      flightDate: new Date(),
      remake: false,
      city: ''
    }
  },
  created () {
    this.city = sessionStorage.getItem('city')
    this.active = Number(this.$route.query.active)
    // console.log(this.active );
    sessionStorage.setItem('serviceType', this.active)
    let id = this.$route.query.id
    console.log(id)

    // sessionStorage.removeItem('departureTime')
    //     sessionStorage.removeItem('startAddress')
  },
  mounted () {
    // console.log(this.active)
    this.value1 = sessionStorage.getItem('departureTime')

    let endAddress = sessionStorage.getItem('endAddress')
    if (endAddress != null) {
      this.endAddress = sessionStorage.getItem('endAddress')
      this.endIp = sessionStorage.getItem('endIp')
    } else {
      this.topcar = sessionStorage.getItem('startAddress')
    }
    let start = sessionStorage.getItem('startAddress')
    if (start != null) {
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 15,
        center: sessionStorage.getItem('startIp').split(','),
      })
      this.topcar = start

      //添加前先移除

      // 获取已经添加的marker
      var markerold = this.map.getAllOverlays('marker')

      // 单独移除点标记
      this.map.remove(markerold)

      var point = sessionStorage.getItem('startIp').split(',')

      var marker = new AMap.Marker({
        position: new AMap.LngLat(point[0], point[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '默认图标',
      })
      // // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker)
    } else {
      this.initMap()
    }


  },
  methods: {
    async bascitys (city) {
      let res = await bascitys_api({
        cityName: city
      })
      //console.log(res);
      if (res.code == 200) {
        //console.log(res.data.cityID);
        sessionStorage.setItem('cityID', res.data.cityID)
      }
    },
    onclick (name, title) {
      sessionStorage.setItem('serviceType', name)
      if (title) {
        this.value1 = ''
        this.value2 = ''
        sessionStorage.removeItem('departureTime')
        //sessionStorage.removeItem('startAddress')
      }
    },
    //点击切换
    fly () {
      //   console.log(111222)
      this.aviation()
    },
    //状态
    async aviation () {
      let res = await aviation_api({
        flightNum: this.flightNum,
        flightDate: this.value2,
      })
      //var city = sessionStorage.getItem('city')
      //   console.log('city',city);
      //   console.log(res.data.FlightDetailNodes[0].FlightArr);
      if (res.code == 200) {
        if ('成都'.indexOf(res.data.FlightDetailNodes[0].FlightArr) != -1) {
          //  console.log('res', res.data.FlightDetailNodes[0])
          var node = res.data.FlightDetailNodes[0]
          this.add = node.FlightArrAirport + node.FlightTerminal + '航站台'
          //console.log('node.FlightArrtimePlanDate', node.FlightArrtimePlanDate)
          sessionStorage.setItem('startAddress', this.add)
          var e = node.FlightArrLocation.split(',')
          sessionStorage.setItem('startIp', [e[0], e[1]])
          sessionStorage.setItem('departureTime', node.FlightArrtimePlanDate)
          this.remake = true
        } else {
          Toast('目前仅支持当前城市的接送机业务')
          this.flightNum = ''
        }
      } else {
        console.log('res', res)
      }
    },

    //预约确定
    onchange (time) {
      this.showPicker = false
      //   console.log(time)
      this.value1 = moment(time).format('yyyy-MM-DD HH:mm:ss')
      sessionStorage.setItem('departureTime', this.value1)
      //console.log(this.value1)
      //console.log(this.value2);
    },
    onchange2 (time) {
      this.showPicker = false
      //   console.log(time)
      this.value2 = moment(time).format('yyyy-MM-DD')
      //console.log(this.value2)
    },

    //下车跳转页面
    end (e) {
      console.log(e)
      // this.$router.push({
      //   path: '/address',
      // })
      if (e < 3) {
        if (this.value2 != '') {
          sessionStorage.setItem('departureTime', this.value2)
        }
        this.$router.push({
          path: '/address',
          query: { type: e, active: this.active },
        })
      }
      if (e == 3) {
        this.aviation()

        if (this.remake) {
          this.$router.push({ path: '/address', query: { type: 2 } })
        } else {
          Toast('请选择航班信息')
        }
      }
    },
    initMap () {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
      console.log(toast);
      var mapObj = new AMap.Map('container') //以为这里是要获取地图容器的id,所以要写在mounted之中
      var geolocation
      var that = this
      mapObj.plugin('AMap.Geolocation', function () {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: false, // 显示定位按钮，默认：true
          buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        })
        mapObj.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', that.onComplete) // 返回定位信息
        AMap.event.addListener(geolocation, 'error', that.onError) // 返回定位出错信息
      })
    },
    onComplete (obj) {
      Toast.clear()//清除加载效果
      console.log(obj)
      sessionStorage.setItem('city', obj.addressComponent.city)
      this.bascitys(obj.addressComponent.city)
      this.positionInfo = obj
      var res =
        '经纬度：' +
        obj.position +
        '\n精度范围：' +
        obj.accuracy +
        '米\n定位结果的来源：' +
        obj.location_type +
        '\n状态信息：' +
        obj.info +
        '\n地址：' +
        obj.formattedAddress +
        '\n地址信息：' +
        JSON.stringify(obj.addressComponent, null, 4)
      console.log(this.positionInfo)
      console.log(res)
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 15,
        center: [obj.position.lng, obj.position.lat],
      })
      var lng = obj.position.lng
      var lat = obj.position.lat
      //添加前先移除
      // 获取已经添加的marker
      var markerold = this.map.getAllOverlays('marker')

      // 单独移除点标记
      this.map.remove(markerold)

      var marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '默认图标',
      })
      // // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker)

      this.$http({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo',
        params: {
          key: '7a528f38aa043570d415be4dbdbbdf1c',
          location: lng + ',' + lat,
        },
      })
        .then((res) => {
          if (res.status == 200) {
            //  console.log(res.status)
            //  console.log(res)
            this.topcar = res.data.regeocode.formatted_address.substring(
              res.data.regeocode.formatted_address.indexOf('市') + 1
            )
            sessionStorage.setItem('startAddress', this.topcar)
            sessionStorage.setItem('startIp', [lng, lat])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onError (obj) {
      Toast.clear()//清除加载效果
      alert('定位失败，请打开定位重试！')
      console.log(obj)
      this.$router.back()
    },

  },
}
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100vh;
}

#container {
  width: 100%;
  height: 100vh;
}

.infoBox {
  position: fixed;
  bottom: 0px;
  width: 100%;
  //  background: url('../../assets/images/bg.png') no-repeat;
  background-size: 100% 100%;
  .tabs {
    width: 90%;
    margin: auto;
    // margin-bottom: 15px;
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .locationInfo {
    height: 120px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    .input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 10px;
      border: 0;
      width: 80%;
      background-color: transparent;
    }
    .statrt {
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
    }

    .end {
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
    }
  }
  .locationInfo2 {
    height: 165px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    .input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 10px;
      border: 0;
      width: 80%;
      background: #fff;
    }
    .statrt {
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
    }
    .time {
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
    }
    .end {
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
    }
  }
  .locationInfo3 {
    height: 180px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    .statrt {
      height: 50px;
      line-height: 30px;
      margin-left: 20px;
    }
    .time {
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
    }
    .end {
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
    }
  }
}
</style>
