// page:身份验证类接口
// time:2021.8.19

import axios from '../axios'
// 
// 预估车费
export const price_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/taxi/estimateprice", // 请求地址
        params: data,
    });
};
// 创建订单
export const order_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/order/createorder", // 请求地址
        data: data,
    });
};
// 取消订单
export const no_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/order/cancelorder", // 请求地址
        data: data,
    });
};
// 订单记录
export const record_api = (data) => {
  return axios({
      method: "get", // 请求方式
      url: "/order/loadOrderRecord", // 请求地址
      params: data,
  });
};
// 订单详情
export const orderdetail_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/order/orderdetail", // 请求地址
        params: data,
    });
  };
  //通过订单号查询订单详情
  export const loadorder_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/order/loadOrderById", // 请求地址
        params: data,
    });
  };
//通过订单号查询订单详情
export const useCoupon_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/coupon/getUseCoupon", // 请求地址
        params: data,
    });
};
//支付订单
export const pay_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/order/payMoney", // 请求地址
        params: data,
    });
};
//支付订单
export const load_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/order/loadUnfinishOrder", // 请求地址
        params: data,
    });
};
//支付订单
export const aviation_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/picKUp/aviation", // 请求地址
        params: data,
    });
};
//城市查询
//支付订单
export const bascitys_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/taxi/bascitys", // 请求地址
        params: data,
    });
};
